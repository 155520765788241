/**
 *
 * @constructor
 * @param nosfield
 */
function selectSearch(nosfield){
	/**
	 * @type {nosfield}
	 */
	this.nosfield = nosfield;
	
	/**
	 * @type {{}}
	 */
	this.searchObj = {};
	
	/**
	 * @type {object[]}
	 */
	this.cache = [];
	
	/**
	 * @type {boolean}
	 */
	this.initialSearchDone = false;
	
	/**
	 * @type {string}
	 * @public
	 */
	this.lastSearchString = '';
}

/**
 * Initialisierung des Objects
 * @public
 */
selectSearch.prototype.init = function(){
	/*
	* searchTimeout wird auf 1ms überschrieben, da wir keine Ajaxcalls haben
	*/
	this.nosfield.options.searchTimeout = 1;
	
	this.nosfield.event.on('valueCleared', () => {
		this.resetLastSearchString()
			.nosfield.renderer.replaceView(this.searchObj);
	});
	
	if(this.nosfield.options.searchObj){
		this.searchObj = this.nosfield.options.searchObj;
	}else{
		/*
		* soll searchObj aus sessionStorage bezogen werden?
		*/
		if(this.nosfield.options.useSessionStorage && this.nosfield.options.sessionStorageKey){
			try{
				this.searchObj = JSON.parse(sessionStorage[this.nosfield.options.sessionStorageKey]);
			}catch(e){
				//invalides JSON
				this.searchObj = {};
				this.nosfield.logger.log('error', 'invalid json Object in localStorage');
			}
		}else{
			this.nosfield.logger.log('warning', 'selectSearch no searchObj');
			this.searchObj = {};
		}
	}
	this.search();
	this.initialSearchDone = true;
	
	
	if(this.nosfield.options.selectedId){
		const skip = this.nosfield.skipDoAfter;
		if(this.nosfield.options.selectedIdSkipDoAfter){
			this.nosfield.skipDoAfter = true;
		}
		
		if(this.nosfield.elements.result && this.nosfield.elements.result.querySelector(`[data-id="${this.nosfield.options.selectedId}"]`)){
			this.nosfield.selector.selectById(this.nosfield.options.selectedId).getSelectedEl().click();
		}
		
		if(this.nosfield.options.selectedIdSkipDoAfter){
			this.nosfield.skipDoAfter = skip;
		}
		//nochmal die initiale suche durchlaufen lassen, wegen selectedId
		this.initialSearchDone = false;
		this.search();
		this.initialSearchDone = true;
	}else{
		if(this.nosfield.options.selectedIds.length){
			const skip = this.nosfield.skipDoAfter;
			if(this.nosfield.options.selectedIdSkipDoAfter){
				this.nosfield.skipDoAfter = true;
			}
			
			this.nosfield.options.selectedIds.forEach(id => {
				if(this.nosfield.elements.result && this.nosfield.elements.result.querySelector(`[data-id="${id}"]`)){
					this.nosfield.selector.selectById(id).getSelectedEl().click();
				}
			});
			
			if(this.nosfield.options.selectedIdSkipDoAfter){
				this.nosfield.skipDoAfter = skip;
			}
			
			this.initialSearchDone = false;
			this.search();
			this.initialSearchDone = true;
		}
	}
};

/**
 * Führt die eigentliche Suche aus
 * @private
 * @param {boolean} silent - soll der Abdunkler genutzt werden
 * @param {function} callback
 */
selectSearch.prototype.genericSearch = function(silent, callback){
	let result = [];
	
	const searchString = this.nosfield.util.normalize(this.nosfield.elements.view.value.trim());
	if(searchString){
		if(searchString !== this.lastSearchString){
			this.lastSearchString = searchString;
			const searchArray = searchString.split(' ');

			let searchFields = {};
			for(let row in this.searchObj){
				searchFields = this.nosfield.options.searchFields;
				if(!searchFields.length){
					searchFields = Object.keys(this.searchObj[row]);
				}
				
				let foundSearchwords = new Set();
				for(let key in searchFields){
					for(let x of searchArray){
						if(this.searchObj[row][searchFields[key]].toString().toLowerCase().includes(x.toLowerCase())){
							foundSearchwords.add(x.toLowerCase());
						}
					}
					
				}
				if(foundSearchwords.size === searchArray.length){
					result.push(this.searchObj[row]);
					// break;
				}
			}
		}else{
			return [];
		}
	}else{
		this.lastSearchString = searchString;
		result = this.searchObj;
	}
	result = this.nosfield.options.sortFunction(result, this.nosfield);
	if(callback){
		return callback(result);
	}
	return result;
}
;

/**
 * Suche nachdem etwas geschrieben wurde
 * @public
 * @param {boolean} force
 */
selectSearch.prototype.search = function(force = false){
	this.genericSearch(false, (result) => {
		if(result.length){
			this.nosfield.renderer.replaceView(result);
			if(this.initialSearchDone){
				this.nosfield.renderer.showResult();
			}
		}else{
			this.nosfield.renderer.clearView();
			this.nosfield.renderer.hideResult();
		}
	});
};

/**
 * Dummy Methode, damit ein externer Zugriff nicht fehlschlägt
 * @public
 */
selectSearch.prototype.preload = function(){
	return [];
};

/**
 * Dummy Methode, damit ein externer Zugriff nicht fehlschlägt
 * @public
 */
selectSearch.prototype.silentPreload = function(){
	return [];
};


/**
 * @public
 * @returns {selectSearch}
 */
selectSearch.prototype.resetLastSearchString = function(){
	this.lastSearchString = '';
	return this;
};

/**
 *
 * @param {array} searchArray
 * @param {boolean} silent
 * @returns {selectSearch}
 */
selectSearch.prototype.resetSearchObject = function(searchArray, silent = false){
	this.searchObj = searchArray;
	if(!silent){
		this.search();
	}
	return this;
};