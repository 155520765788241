/**
 * @param {nosfield2} nosfield
 * @constructor
 */
function ResultRendererMulti(nosfield){
	this.nosfield = nosfield;
	
	this.view = null;
	this.cache = [];
	
	//icons und viewKinds Indices müssen zussammenpassen
	/**
	 * @var {String[]}
	 */
	this.viewKinds = [
		'line-nowrap',
		'line-wrap',
		'table',
	];
	/**
	 * @var {String[]}
	 */
	this.icons = [
		'linear_scale',
		'line_style',
		'view_headline',
	];
	
	this.icon_elements = {};
	
}

/**
 * @private
 * @return {HTMLDivElement}
 */
ResultRendererMulti.prototype.createNav = function(){
	const nav = document.createElement('div');
	nav.classList.add('nosfield--ResultRendererMulti--nav');
	
	this.icons.forEach((iconType, index) => {
		const icon = document.createElement('div');
		icon.classList.add('material-icons');
		icon.classList.add('nosfield--view_switch');
		icon.dataset.style = this.viewKinds[index];
		icon.innerText = iconType;
		
		icon.onclick = () => {
			this.setStyle(this.viewKinds[index]);
		};
		
		this.icon_elements[this.viewKinds[index]] = icon;
		
		nav.appendChild(icon);
	});
	
	return nav;
};

/**
 * @param {string} style
 * @return {boolean}
 */
ResultRendererMulti.prototype.setStyle = function(style){
	if(!this.viewKinds.includes(style)){
		this.nosfield.logger.error(`ResultRendererMulti: wrong style ${style}`, {});
		return false;
	}
	if(this.view && this.currentStyle){
		this.view.className = 'nosfield--ResultRendererMulti--list';
	}
	
	this.currentStyle = style;
	if(this.view){
		this.view.classList.add(`nosfield--${this.currentStyle}`);
		for(let i in this.icon_elements){
			this.icon_elements[i].classList.remove('selected_style_switch');
		}
		this.icon_elements[style].classList.add('selected_style_switch');
	}
	this.resizeFlyingWindow();
	return true;
};

ResultRendererMulti.prototype.init = function(){
	this.nosfield.event.on('ready', () => {
		if(this.nosfield.options.resultRendererViewBorder === ''){
			this.nosfield.logger.error('empty result border for ResultRendererMulti, check nosfield.options.resltRendererViewBorder');
			return;
		}
		const viewBorder = document.querySelector(this.nosfield.options.resultRendererViewBorder);
		
		const borderEl = document.createElement('div');
		if(!this.nosfield.options.hideMultiselectStyleChange){
			borderEl.classList.add('nosfield--ResultRendererMulti--border');
		}
		this.view = document.createElement('div');
		
		const nav = this.createNav();
		
		this.view.classList.add('nosfield--ResultRendererMulti--list');
		
		this.view.classList.add(`nosfield--${this.currentStyle}`);
		
		borderEl.appendChild(this.view);
		if(!this.nosfield.options.hideMultiselectStyleChange){
			borderEl.appendChild(nav);
		}
		
		if(viewBorder){
			viewBorder.appendChild(borderEl);
			
			if(viewBorder.parentElement.tagName === 'TD'){
				viewBorder.parentElement.style.maxWidth = '1000px';
			}
			const sortable = this.nosfield.options.multiselectSortable;
			this.nosfield.options.multiselectSortable = false;
			this.cache.forEach(obj => {
				this.setValue(obj, sortable);
			});
			this.nosfield.options.multiselectSortable = sortable;
			if(this.nosfield.options.multiselectSortable){
				jQuery(this.view).sortable({
					handle: '.glyphicon-sort',
					cursorAt: {left: 25},
				});
			}
			
		}else{
			this.nosfield.logger.error('missing Border element for query: {query}', {query: this.nosfield.options.resultRendererViewBorder,});
		}
		this.setStyle('table');
		this.resizeFlyingWindow();
		this.nosfield.elements.hidden.remove();
	});
	
};

/**
 * @private
 * @return {ResultRendererMulti}
 */
ResultRendererMulti.prototype.resizeFlyingWindow = function(){
	if(this.nosfield.is_in_flying_window && erp_design.nosfly[this.nosfield.flying_window].getResizeStatus() === 'small'){
		erp_design.nosfly[this.nosfield.flying_window].resizeToContent();
	}
	return this;
}

/**
 *
 * @param {string} id
 * @returns {null|HTMLLIElement}
 */
ResultRendererMulti.prototype.getExisting = function(id){
	for(let el of this.view.querySelectorAll('div')){
		if(el.querySelector('input[type="hidden"]').value === id){
			return el;
		}
	}
	return null;
};

/**
 * @param {object} obj
 * @param {?string} obj.view
 * @param {?string} obj.hidden
 * @param {?boolean} orgSort
 * @returns {ResultRendererMulti}
 */
ResultRendererMulti.prototype.setValue = function(obj, orgSort = false){
	if(!this.view){
		this.cache.push(obj);
		return this;
	}
	const view = obj.view || '';
	const hidden = obj.hidden || '';
	
	if(view === '' && hidden === ''){
		return this;
	}
	
	const existing = this.getExisting(obj.hidden);
	
	if(existing){
		const before = existing.style.background;
		existing.style.background = '#DAE073';
		setTimeout(() => {
			existing.style.background = before;
		}, 1000);
		return this;
	}
	
	const viewEl = document.createElement('div');
	
	viewEl.className = 'nosfieldResultRendererMulti--listElement';
	viewEl.dataset.id = obj.hidden;
	
	const text = document.createElement('span');
	text.className = 'nosfieldResultRendererMultiView';
	text.innerText = view;
	
	
	const remove = document.createElement('span');
	if(!this.nosfield.options.materialIcons){
		remove.className = 'glyphicon glyphicon-minus-sign';
	}else{
		remove.classList.add('material-icons', 'clickable');
		remove.innerText = 'close';
	}
	remove.style['margin-right'] = '5px';
	remove.onclick = () => {
		this.nosfield.event.emit('multiRendererRemove', {
			id: obj.hidden,
		});
		viewEl.remove();
	};
	
	const hiddenEl = document.createElement('input');
	hiddenEl.type = 'hidden';
	hiddenEl.value = hidden;
	hiddenEl.className = 'nosfieldResultRendererMultiHidden';
	
	if(this.nosfield.options.fieldName.indexOf('[') > -1){
		const hasContent = this.nosfield.options.fieldName.match(/\[([^\]]+)\]/);
		if(hasContent){
			hiddenEl.name = this.nosfield.options.fieldName.replace(hasContent[1], `${hasContent[1]}__${hidden}`);
		}else{
			hiddenEl.name = `${this.nosfield.options.fieldName}`;
		}
	}else{
		hiddenEl.name = `${this.nosfield.options.fieldName}[]`;
	}
	
	
	viewEl.appendChild(remove);
	
	if(this.nosfield.options.multiselectSortable || orgSort){
		const sortHandle = document.createElement('span');
		sortHandle.className = 'glyphicon glyphicon-sort';
		viewEl.appendChild(sortHandle);
	}
	
	viewEl.appendChild(text);
	
	viewEl.appendChild(hiddenEl);
	
	this.view.appendChild(viewEl);
	this.resizeFlyingWindow();
	
	this.nosfield.elements.clearButton.style.display = 'none';
	
	if(this.nosfield.options.multiselectSortable){
		jQuery(this.view).sortable('refresh');
	}
	
	return this;
};


/**
 *
 * @returns {[{view:string,hidden:string}]}
 */
ResultRendererMulti.prototype.getValue = function(){
	const result = [];
	this.view.querySelectorAll('div').forEach(li => {
		const view = li.querySelector('.nosfieldResultRendererMultiView').innerText;
		const hidden = li.querySelector('.nosfieldResultRendererMultiHidden').value;
		result.push({
			view: view,
			hidden: hidden,
		});
	});
	
	return result;
};

/**
 * @param obj
 * @param {string} obj.view
 * @param {string} obj.hidden
 */
ResultRendererMulti.prototype.addValue = function(obj){
	this.setValue(obj);
};

ResultRendererMulti.prototype.clear = function(){
	this.view.querySelectorAll('div').forEach(value => {
		value.remove();
	});
	this.nosfield.elements.view.value = '';
	this.nosfield.event.emit('valueCleared', {nosfieldId: this.nosfield.id,});
	this.resizeFlyingWindow();
};